<template>
  <ul ref="legend" class="flex flex-wrap"></ul>
  <h2 v-if="title" class="font-simplerBold text-sm">{{ title }}</h2>

  <div class="grow min-h-40">
    <canvas ref="canvas" :data="data" width="100%" height="100%"></canvas>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import { onMounted, onUnmounted, ref } from 'vue';
import type { ChartData } from 'chart.js';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  TimeScale,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';

Chart.register(
  BarController,
  BarElement,
  LinearScale,
  TimeScale,
  Tooltip,
  CategoryScale,
  Legend,
);

const canvas = ref();
let barChart;

const props = defineProps({
  data: {
    type: Object as PropType<ChartData>,
    default: () => {},
  },
  title: {
    type: String,
    default: null,
  },
  width: {
    type: Number,
    default: 289,
  },
  height: {
    type: Number,
    default: 300,
  },
});

const initChart = () => {
  const barCtx = canvas.value;
  const options: any = {
    scales: {
      y: {
        ticks: {
          maxTicksLimit: 7,
          callback: value => value,
        },
      },
      x: {
        ticks: {
          maxTicksLimit: 5,
          // @ts-ignore
          callback: (value, index) => props.data?.labels[index],
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          title: () => false,
          label: context => context.parsed.y,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'nearest',
    },
    animation: {
      duration: 500,
    },
    maintainAspectRatio: false,
    resizeDelay: 200,
  };

  const config: any = {
    type: 'bar',
    data: props.data,
    options,
  };

  barChart = new Chart(barCtx, config);
};

onMounted(() => initChart());
onUnmounted(() => barChart.destroy());
</script>
